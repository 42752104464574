'use client'

import dynamic from 'next/dynamic'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { useTheme, alpha } from '@mui/material/styles'
import { Container, Grid, Typography, IconButton } from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material'

// Types
import type { FooterProps } from '@/types/components/molecules/footer'

// Helpers
import { useSettingsStore } from '@/helpers/store/settings'

// Components
const MenuItemsMolecule = dynamic(() => import('@/components/molecules/menu/items'))
const SuggestionButtonAtom = dynamic(() => import('@/components/atoms/buttons/suggestion'))
const CopyRightAtom = dynamic(() => import('@/components/atoms/copyright'))

const FooterMolecule = (props: FooterProps) => {
	// Props
	const { items } = props

	// Variable
	const { footerShow, setFooterShow } = useSettingsStore()
	const theme = useTheme()
	const pathname = usePathname()

	return (
		<Grid item position="relative" mt={{ xs: pathname !== '/' && pathname !== '/suggestion' ? 20 : 'inherit', md: 'inherit' }}>
			{items?.length && (
				<IconButton
					onClick={() => setFooterShow(!footerShow)}
					sx={{
						position: 'absolute',
						top: 0,
						left: { xs: 4, smmd: 30 },
						color: 'primary.contrastText',
						bgcolor: 'primary.dark',
						borderRadius: 1,
						transform: 'translateY(-50%)',
						'&:hover': {
							color: 'primary.contrastText',
							bgcolor: 'primary.dark'
						}
					}}
				>
					{footerShow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			)}

			{pathname !== '/' && pathname !== '/suggestion' && <SuggestionButtonAtom />}

			{items && items.length ? (
				<Grid
					container
					sx={{
						display: footerShow ? 'flex' : 'none',
						...(pathname === '/' && {
							background: `linear-gradient(to bottom, ${alpha('#181818', 0)} 0%, ${alpha('#181818', theme.palette.mode === 'dark' ? 0.7 : 0.5)} 90%)`
						}),
						borderTop: `1px solid ${alpha(pathname === '/' ? theme.palette.common.white : '#706F74', pathname === '/' ? 0.4 : 0.16)}`,
						'& .MuiButtonBase-root': {
							px: 0,
							'& a': { color: pathname === '/' ? 'common.white' : '#706f74' }
						}
					}}
				>
					<Container maxWidth="lg">
						<Grid container justifyContent="space-between" spacing={{ smmd: 2 }} pt={5}>
							{items.map((menus, index) => {
								return (
									<Grid key={index} item xs={12} smmd={12 / Math.max(items.length, 1)}>
										{index ? (
											menus.length && menus[0].group && <Typography fontWeight={700}>{menus[0].group?.title}</Typography>
										) : (
											<Image src="/images/tlicho-govenment.png" width={107} height={84} alt="tlicho govenment logo" />
										)}

										<MenuItemsMolecule items={menus} />
									</Grid>
								)
							})}
						</Grid>
					</Container>
				</Grid>
			) : null}

			<CopyRightAtom />
		</Grid>
	)
}

export default FooterMolecule
