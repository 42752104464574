import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

// Configurations
import Config from '@/configs'

// Types
import type { SettingsStore } from '@/types/helpers/store/settings'

// Store
export const useSettingsStore = create(
	persist<SettingsStore>(
		set => ({
			footerShow: false,
			setFooterShow: (footerShow: SettingsStore['footerShow']) => set({ footerShow })
		}),
		{
			name: `${Config.shortName.toLocaleLowerCase()}-settings`,
			storage: createJSONStorage(() => localStorage)
		}
	)
)
